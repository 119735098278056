<template>
  <NavbarDefault />
  <div id="form_pendaftaran" class="poppins">
    <div class="mt-5 pt-5"></div>
    <h4 class="text-center fw-bold">FORMULIR KIP KULIAH {{ tahunAktif }}</h4>
    <div class="text-center mb-3 fs-7">
      Form pendaftaran GRATIS! ( tidak ada kutipan biaya apapun )
    </div>

    <div class="container">
      <form @submit.prevent="insert">
        <div class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic">
          DATA DIRI
        </div>
        <div class="w-100 h-100 border rounded-bottom border-2 px-4 py-3 mb-5">
          <div class="row">
            <div class="col-12 col-md-3">
              <label for="nama" class="form-label">
                Nama Lengkap <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="nama" class="form-control" id="nis" v-model="currentRecord.nama_lengkap"
                disabled />
            </div>
            <div class="col-12 col-md-3">
              <label for="nis" class="form-label">
                Jenis Kelamin <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <div class="" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" class="btn-check" name="JenisKelamin" id="Pria" autocomplete="off"
                  v-bind:checked="selectedOption2 === 'Pria'" v-on:change="updateSelectedOption2('Pria')" />
                <label class="btn btn-outline-secondary me-1" for="Pria">Pria</label>

                <input type="radio" class="btn-check" name="JenisKelamin" id="Wanita" autocomplete="off"
                  v-bind:checked="selectedOption2 === 'Wanita'" v-on:change="updateSelectedOption2('Wanita')" />
                <label class="btn btn-outline-secondary" for="Wanita">Wanita</label>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <label for="agama" class="form-label">
                Agama <span class="text-danger">*</span>
              </label>
            </div>
            <div v-if="isMobile == false" class="col-12 col-md-9 mb-3 d-md-block d-none">
              <div class="" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" class="btn-check" name="agama" id="Islam" autocomplete="off"
                  v-bind:checked="selectedOption === 'Islam'" v-on:change="updateSelectedOption('Islam')" />
                <label class="btn btn-outline-secondary me-1" for="Islam">Islam</label>

                <input type="radio" class="btn-check" name="agama" id="Protestan" autocomplete="off"
                  v-bind:checked="selectedOption === 'Protestan'" v-on:change="updateSelectedOption('Protestan')" />
                <label class="btn btn-outline-secondary me-1" for="Protestan">Protestan</label>
                <input type="radio" class="btn-check" name="agama" id="Katolik" autocomplete="off"
                  v-bind:checked="selectedOption === 'Katolik'" v-on:change="updateSelectedOption('Katolik')" />
                <label class="btn btn-outline-secondary me-1" for="Katolik">katolik</label>
                <input type="radio" class="btn-check" name="agama" id="Hindu" autocomplete="off"
                  v-bind:checked="selectedOption === 'Hindu'" v-on:change="updateSelectedOption('Hindu')" />
                <label class="btn btn-outline-secondary me-1" for="Hindu">Hindu</label>
                <input type="radio" class="btn-check" name="agama" id="Buddha" autocomplete="off"
                  v-bind:checked="selectedOption === 'Buddha'" v-on:change="updateSelectedOption('Buddha')" />
                <label class="btn btn-outline-secondary me-1" for="Buddha">Buddha</label>
                <input type="radio" class="btn-check" name="agama" id="Konghuchu" autocomplete="off"
                  v-bind:checked="selectedOption === 'Konghuchu'" v-on:change="updateSelectedOption('Konghuchu')" />
                <label class="btn btn-outline-secondary me-1" for="Konghuchu">Konghuchu</label>
              </div>
            </div>

            <div v-else class="col-12 col-md-9 mb-3 d-block d-md-none">
              <select name="" id="" class="form-select" v-model="currentRecord.agama" required>
                <option value="Islam">Islam</option>
                <option value="Protestan">Protestan</option>
                <option value="Katolik">Katolik</option>
                <option value="Buddha">Buddha</option>
                <option value="Hindu">Hindu</option>
                <option value="Konghuchu">Konghuchu</option>
              </select>
            </div>

            <div class="col-12 col-md-3">
              <label for="nik" class="form-label">
                No Induk Kependudukan (NIK)<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="nik" class="form-control" id="nis" v-model="currentRecord.no_ktp" minlength="16"
                maxlength="16" disabled />
            </div>
            <div class="col-12 col-md-3">
              <label for="nis" class="form-label">
                Tempat Lahir <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="nis" class="form-control" id="nis" v-model="currentRecord.tempat_lahir"
                required />
            </div>
            <div class="col-12 col-md-3">
              <label for="tanggal_lahir" class="form-label">
                Tanggal Lahir <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="date" name="tanggal_lahir" class="form-control" id="nis"
                v-model="currentRecord.tanggal_lahir" required />
            </div>
            <div class="col-12 col-md-3">
              <label for="alamat" class="form-label">
                Alamat Lengkap<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="alamat" class="form-control" id="nis" v-model="currentRecord.alamat_siswa"
                required />
            </div>

            <div class="col-12 col-md-3">
              <label for="nis" class="form-label">
                Provinsi <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input list="provinsi" v-model="currentRecord.provinsi" class="form-control"
                @change="checkInputValidity_wilayah('provinsi')" required />
              <datalist id="provinsi">
                <option v-for="provinsi in provinsiList" :value="provinsi.nama" :key="provinsi.nama"></option>
              </datalist>
            </div>
            <div class="col-12 col-md-3">
              <label for="kota" class="form-label">
                Kota/kabupaten<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input list="kota" v-model="currentRecord.kota" class="form-control"
                @change="checkInputValidity_wilayah('kota')" required />
              <datalist id="kota">
                <option v-for="kota in kotaList" :value="kota.nama" :key="kota.nama"></option>
              </datalist>
            </div>
            <div class="col-12 col-md-3">
              <label for="kecamatan" class="form-label">
                Kecamatan <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input list="kecamatan" v-model="currentRecord.kecamatan" class="form-control"
                @change="checkInputValidity_wilayah('kecamatan')" required />
              <datalist id="kecamatan">
                <option v-for="kecamatan in kecamatanList" :value="kecamatan.nama" :key="kecamatan.nama"></option>
              </datalist>
            </div>
            <div class="col-12 col-md-3">
              <label for="kelurahan" class="form-label">
                Kelurahan <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input list="kelurahan" v-model="currentRecord.kelurahan" class="form-control"
                @change="checkInputValidity_wilayah('kelurahan')" required />
              <datalist id="kelurahan">
                <option v-for="kelurahan in kelurahanList" :value="kelurahan.nama" :key="kelurahan.nama"></option>
              </datalist>
            </div>
            <div class="col-12 col-md-3">
              <label for="kode_pos" class="form-label">
                Lingkungan <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="lingkungan" class="form-control" id="lingkungan" placeholder=""
                v-model="currentRecord.lingkungan" required />
            </div>
            <div class="col-12 col-md-3">
              <label for="kode_pos" class="form-label">
                Kode POS <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 mb-2">
              <input type="text" name="kode_pos" class="form-control" id="kode_pos" placeholder=""
                v-model="currentRecord.kodepos" required />
            </div>
            <div class="col-12 col-md-3">
              <label for="telepon_siswa" class="form-label">
                No Handphone<span class="text-danger"></span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="tel" name="telepon_siswa" class="form-control" id="telepon_siswa"
                v-model="currentRecord.telepon_siswa" placeholder="Masukan no hp" />
            </div>
            <div class="col-12 col-md-3">
              <label for="whatsapp" class="form-label">
                No Whatsapp<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="tel" name="whatsapp" class="form-control" id="whatsapp" v-model="currentRecord.whatsapp"
                placeholder="Masukan no whatsapp yang aktif" disabled />
            </div>

            <div class="col-12 col-md-3">
              <label for="email" class="form-label">
                Email <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="email" class="form-control" id="nis" placeholder="Masukan email"
                v-model="currentRecord.email" />
            </div>

            <!-- <div class="col-12 col-md-3">
              <label for="nisn" class="form-label">
                Nomor Induk Siswa Nasional (NISN)<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="nisn" class="form-control" id="nis" v-model="currentRecord.nisn" />
            </div> -->

            <div class=""></div>
          </div>
        </div>

        <div class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic">
          DATA KELUARGA
        </div>
        <div class="w-100 h-100 border rounded-bottom border-2 px-4 py-3 mb-5">
          <div class="row">
            <div class="col-12 col-md-3">
              <label for="no_kk" class="form-label">
                NO Kartu Keluarga <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="no_kk" class="form-control" id="no_kk" v-model="currentRecord.no_kk"
                minlength="16" maxlength="16" required />
            </div>

            <div class="col-12 col-md-3">
              <label for="nama_ayah" class="form-label">
                Nama Ayah <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="nama_ayah" class="form-control" id="nis" v-model="currentRecord.nama_ayah"
                required />
            </div>
            <div class="col-12 col-md-3">
              <label for="nik" class="form-label">
                NIK Ayah <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="nik_ayah" class="form-control" id="nik_ayah" v-model="currentRecord.nik_ayah"
                minlength="16" maxlength="16" required />
            </div>

            <div class="col-12 col-md-3">
              <label for="pekerjaan_ayah" class="form-label">
                Pekerjaan Ayah<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="pekerjaan_ayah" class="form-control" id="pekerjaan_ayah"
                v-model="currentRecord.pekerjaan_ayah" required />
              <!-- <select class="form-select" aria-label="Default select example" name="pekerjaan_ayah" id="pekerjaan_ayah"
                v-model="pekerjaan_ayah" required>
                <option v-for="pekerjaanList in pekerjaanList" :key="pekerjaanList.nama" :value="pekerjaanList.nama">
                  {{ pekerjaanList.nama }}
                </option>
              </select> -->
            </div>
            <div class="col-12 col-md-3">
              <label for="pendidikan_terakhir_ayah" class="form-label">
                Pendidikan Terakhir Ayah<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input list="pendidikanTerakhirAyah" v-model="currentRecord.pendidikan_terakhir_ayah" class="form-control"
                @change="checkInputValidity('pendidikan_terakhir_ayah')" required />
              <datalist id="pendidikanTerakhirAyah">
                <option v-for="data in pendidikanTerakhirList" :value="data.nama" :key="data.nama"></option>
              </datalist>
            </div>

            <div class="col-12 col-md-3">
              <label for="nama_ibu" class="form-label">
                Nama Ibu <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="nama_ibu" class="form-control" id="nama_ibu" v-model="currentRecord.nama_ibu"
                required />
            </div>
            <div class="col-12 col-md-3">
              <label for="nik" class="form-label">
                NIK Ibu <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="nik_ibu" class="form-control" id="nik_ibu" v-model="currentRecord.nik_ibu"
                minlength="16" maxlength="16" required />
            </div>
            <div class="col-12 col-md-3">
              <label for="pekerjaan_ibu" class="form-label">
                Pekerjaan Ibu<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="text" name="pekerjaan_ibu" class="form-control" id="pekerjaan_ibu"
                v-model="currentRecord.pekerjaan_ibu" required />
              <!-- <select class="form-select" aria-label="Default select example" name="pekerjaan_ibu" id="pekerjaan_ibu"
                v-model="pekerjaan_ibu" required>
                <option v-for="pekerjaanList in pekerjaanList" :key="pekerjaanList.nama" :value="pekerjaanList.nama">
                  {{ pekerjaanList.nama }}
                </option>
              </select> -->
            </div>
            <div class="col-12 col-md-3">
              <label for="pendidikan_terakhir_ibu" class="form-label">
                Pendidikan Terakhir Ibu<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input list="pendidikanTerakhirIbu" v-model="currentRecord.pendidikan_terakhir_ibu" class="form-control"
                @change="checkInputValidity('pendidikan_terakhir_ibu')" required />
              <datalist id="pendidikanTerakhirIbu">
                <option v-for="data in pendidikanTerakhirList" :value="data.nama" :key="data.nama"></option>
              </datalist>
            </div>

            <div class="col-12 col-md-3">
              <label for="jumlah_tanggungan" class="form-label">
                Jumlah Saudara <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="number" name="jumlah_tanggungan" class="form-control" id="jumlah_tanggungan"
                v-model="currentRecord.jumlah_tanggungan" required />
            </div>

            <div class="col-12 col-md-3">
              <label for="telepon_ortu" class="form-label">
                No. HP Orang tua <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="tel" name="telepon_ortu" class="form-control" id="telepon_ortu"
                v-model="currentRecord.telepon_ortu" required />
            </div>

            <div class="col-12 col-md-3">
              <label for="whatsapp_ortu" class="form-label">
                No. WA Orang tua <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <input type="tel" name="whatsapp_ortu" class="form-control" id="whatsapp_ortu"
                v-model="currentRecord.whatsapp_ortu" required />
            </div>

            <div class="col-12 col-md-3">
              <label for="status_ortu" class="form-label">
                Status Orang tua<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3">
              <select class="form-select" aria-label="Default select example" name="status_ortu" id="status_ortu"
                v-model="statusOrangTua" required>
                <option v-for="statusOrangTuaList in statusOrangTuaList" :key="statusOrangTuaList.nama"
                  :value="statusOrangTuaList.nama">
                  {{ statusOrangTuaList.nama }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-3">
              <label for="penghasilan_ayah" class="form-label">
                Penghasilan Ayah<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="number" name="penghasilan_ayah" class="form-control ps-5" id="penghasilan_ayah"
                v-model="currentRecord.penghasilan_ayah" required />
              <div class="position-absolute top-0 h-100 ps-3">
                <div class="d-flex align-items-center h-100">Rp.</div>
              </div>
              <div class="position-absolute top-0 end-0 h-100">
                <div class="d-flex align-items-center h-100 pe-4 me-1">
                  /Bulan
                </div>
              </div>
            </div>

            <div class="col-12 col-md-3">
              <label for="penghasilan_ibu" class="form-label">
                Penghasilan Ibu<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="number" name="penghasilan_ibu" class="form-control ps-5" id="penghasilan_ibu"
                v-model="currentRecord.penghasilan_ibu" required />
              <div class="position-absolute top-0 h-100 ps-3">
                <div class="d-flex align-items-center h-100">Rp.</div>
              </div>
              <div class="position-absolute top-0 end-0 h-100">
                <div class="d-flex align-items-center h-100 pe-4 me-1">
                  /Bulan
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic">
          PENDIDIKAN
        </div>
        <div class="w-100 h-100 border rounded-bottom border-2 px-4 py-3 mb-5">
          <div class="row">
            <div class="col-12 col-md-3">
              <label for="nama_sekolah" class="form-label">
                Nama Sekolah Asal<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="text" name="nama_sekolah" class="form-control" id="nama_sekolah"
                v-model="currentRecord.nama_sekolah" required />
            </div>

            <div class="col-12 col-md-3">
              <label for="npsn_sekolah" class="form-label">
                NPSN<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="text" name="npsn_sekolah" class="form-control" id="npsn_sekolah"
                v-model="currentRecord.npsn_sekolah" required />
            </div>

            <div class="col-12 col-md-3">
              <label for="nisn_sekolah" class="form-label">
                NISN<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="text" name="nisn_sekolah" class="form-control" id="nisn_sekolah"
                v-model="currentRecord.nisn_sekolah" required />
            </div>

            <div class="col-12 col-md-3">
              <label for="jurusan_sekolah" class="form-label">
                Jurusan SMA/SMK<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="text" name="jurusan_sekolah" class="form-control" id="jurusan_sekolah"
                v-model="currentRecord.jurusan_sekolah" required />
            </div>

            <div class="col-12 col-md-3">
              <label for="tahun_lulus_sekolah" class="form-label">
                Tahun Lulus<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <!-- <input type="number" step="1" name="tahun_lulus_sekolah" class="form-control" id="tahun_lulus_sekolah"
                v-model="currentRecord.tahun_lulus_sekolah" required /> -->
              <select class="form-select" aria-label="Default select example" name="kelurahan" id="kelurahan"
                v-model="currentRecord.tahun_lulus_sekolah" required>
                <option value="" disabled>Pilih</option>
                <option v-for="(list, index) in tahunLulusList" :key="list.nama" :value="list.nama"
                  :selected="index == 0">
                  {{ list.nama }}
                </option>
                <!-- <option v-for="kelurahanList in kelurahanList" :key="kelurahanList.nama" :value="kelurahanList.nama">
                  {{ kelurahanList.nama }}
                </option> -->
              </select>
            </div>

            <div class="col-12 col-md-3">
              <label for="prestasi1_sekolah" class="form-label">
                Prestasi Sekolah 1<span class="text-danger"></span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="text" name="prestasi1_sekolah" class="form-control" id="prestasi1_sekolah"
                v-model="currentRecord.prestasi1_sekolah" />
            </div>

            <div class="col-12 col-md-3">
              <label for="prestasi2_sekolah" class="form-label">
                Prestasi Sekolah 2<span class="text-danger"></span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="text" name="prestasi2_sekolah" class="form-control" id="prestasi2_sekolah"
                v-model="currentRecord.prestasi2_sekolah" />
            </div>
          </div>
        </div>

        <div class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic">
          PILIHAN
        </div>
        <div class="w-100 h-100 border rounded-bottom border-2 px-4 py-3 mb-5">
          <div class="row">
            <div class="col-12 col-md-3">
              <label for="nama_peruguruan_tinggi" class="form-label">
                Nama Perguruan Tinggi<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <select class="form-select" aria-label="Default select example" name="nama_peruguruan_tinggi"
                id="nama_peruguruan_tinggi" v-model="currentRecord.nama_pt" @change="setAlamatPt" required>
                <option v-for="kepemilikanList in kampusList" :key="kepemilikanList.nama" :value="kepemilikanList.nama"
                  :disabled="currentRecord.nama_pt != ''">
                  {{ kepemilikanList.nama }}
                </option>
              </select>
            </div>

            <div class="col-12 col-md-3">
              <label for="alamat_perguruan_tinggi" class="form-label">
                Alamat Perguruan Tinggi<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="text" name="alamat_perguruan_tinggi" class="form-control" id="alamat_perguruan_tinggi"
                v-model="currentRecord.alamat_pt" required />
            </div>

            <div class="col-12 col-md-3">
              <label for="fakultas" class="form-label">
                Fakultas<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="text" name="fakultas" class="form-control" id="fakultas" v-model="currentRecord.fakultas_pt"
                required />
            </div>

            <div class="col-12 col-md-3">
              <label for="prodi" class="form-label">
                Prodi<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="text" name="prodi" class="form-control" id="prodi" v-model="currentRecord.prodi_pt"
                required />
            </div>

            <!-- <div class="col-12 col-md-3">
              <label for="nim" class="form-label">
                NIM<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="text" name="nim" class="form-control" id="nim" v-model="currentRecord.nim_pt" />
            </div> -->
          </div>
        </div>

        <div class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic">
          RUMAH TINGGAL KELUARGA
        </div>
        <div class="w-100 h-100 border rounded-bottom border-2 px-4 py-3 mb-5">
          <div class="row">
            <div class="col-12 col-md-3">
              <label for="alamat" class="form-label">
                Alamat<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="text" name="alamat" class="form-control" id="alamat" v-model="currentRecord.alamat_rumah"
                required />
            </div>

            <div class="col-12 col-md-3">
              <label for="kepemilikan" class="form-label">
                Kepemilikan<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <select class="form-select" aria-label="Default select example" name="kepemilikan" id="kepemilikan"
                v-model="currentRecord.kepemilikan_rumah" required>
                <option v-for="kepemilikanList in kepemilikanList" :key="kepemilikanList.nama"
                  :value="kepemilikanList.nama">
                  {{ kepemilikanList.nama }}
                </option>
              </select>
            </div>
            <div class="col-12 col-md-3">
              <label for="tahun_perolehan" class="form-label">
                Tahun Perolehan<span class="text-danger"></span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="number" min="1900" max="2099" step="1" name="tahun_perolehan" class="form-control"
                id="tahun_perolehan" v-model="currentRecord.tahun_perolehan_rumah" />
            </div>

            <div class="col-12 col-md-3">
              <label for="sumber_listrik" class="form-label">
                Sumber Listrik<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <!-- <input type="text" name="sumber_listrik" class="form-control" id="sumber_listrik"
                v-model="currentRecord.sumber_listrik_rumah" required /> -->
              <select class="form-select" aria-label="Default select example" name="sumber_listrik" id="sumber_listrik"
                v-model="currentRecord.sumber_listrik_rumah" required>
                <option value="" disabled>Pilih</option>
                <option v-for="(list, index) in sumberListrikList" :key="list.nama" :value="list.nama"
                  :selected="index == 0">
                  {{ list.nama }}
                </option>
              </select>
            </div>

            <div class="col-12 col-md-3">
              <label for="luas_bangunan" class="form-label">
                Luas Bangunan<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="number" name="luas_bangunan" class="form-control pe-4" id="luas_bangunan"
                v-model="currentRecord.luas_bangunan_rumah" required />

              <div class="position-absolute top-0 h-100 end-0 pe-3">
                <div class="d-flex align-items-center h-100">m<sup>2</sup></div>
              </div>
            </div>
          </div>
        </div>

        <div class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic">
          AKUN MEDIA SOSIAL
        </div>
        <div class="w-100 h-100 border rounded-bottom border-2 px-4 py-3 mb-5">
          <div class="row">
            <div class="col-12 col-md-3">
              <label for="facebook" class="form-label">
                Facebook<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="text" name="facebook" class="form-control" id="facebook" v-model="currentRecord.facebook"
                required />
            </div>

            <div class="col-12 col-md-3">
              <label for="instagram" class="form-label">
                Instagram<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="text" name="instagram" class="form-control" id="instagram" v-model="currentRecord.instagram"
                required />
            </div>
            <div class="col-12 col-md-3">
              <label for="twitter" class="form-label">
                Twitter<span class="text-danger"></span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="text" name="twitter" class="form-control" id="twitter" v-model="currentRecord.twitter" />
            </div>
            <div class="col-12 col-md-3">
              <label for="tiktok" class="form-label">
                Tiktok<span class="text-danger"></span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="text" name="tiktok" class="form-control" id="tiktok" v-model="currentRecord.tiktok" />
            </div>
            <div class="col-12 col-md-3">
              <label for="youtube" class="form-label">
                Youtube<span class="text-danger"></span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="text" name="youtube" class="form-control" id="youtube" v-model="currentRecord.youtube" />
            </div>
          </div>
        </div>
        <div class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic">
          TIM YANG MEREKOMENDASI
        </div>
        <div class="w-100 h-100 border rounded-bottom border-2 px-4 py-3 mb-5">
          <div class="row">
            <div class="col-12 col-md-3">
              <label for="nama_rekomendasi" class="form-label">
                Nama Lengkap<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="text" name="nama_rekomndasi" class="form-control" id="nama_rekomendasi"
                v-model="currentRecord.nama_rekomendasi" required />
            </div>
            <div class="col-12 col-md-3">
              <label for="pekerjaan_rekomendasi" class="form-label">
                Pekerjaan<span class="text-danger"></span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="text" name="pekerjaan_rekomendasi" class="form-control" id="pekerjaan_rekomendasi"
                v-model="currentRecord.pekerjaan_rekomendasi" />
            </div>
            <div class="col-12 col-md-3">
              <label for="jabatan_rekomendasi" class="form-label">
                Jabatan<span class="text-danger"></span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="text" name="jabatan_rekomendasi" class="form-control" id="jabatan_rekomendasi"
                v-model="currentRecord.jabatan_rekomendasi" />
            </div>
            <div class="col-12 col-md-3">
              <label for="hp_rekomendasi" class="form-label">
                Hp<span class="text-danger"></span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="text" name="hp_rekomendasi" class="form-control" id="hp_rekomendasi"
                v-model="currentRecord.hp_rekomendasi" />
            </div>
            <div class="col-12 col-md-3">
              <label for="alamat_rekomendasi" class="form-label">
                Alamat<span class="text-danger"></span>
              </label>
            </div>
            <div class="col-12 col-md-9 mb-3 position-relative">
              <input type="text" name="alamat_rekomendasi" class="form-control" id="alamat_rekomendasi"
                v-model="currentRecord.alamat_rekomendasi" />
            </div>
            <!-- <div class="col-12 col-md-3 d-none">
              <label for="facebook" class="form-label">
                Tanda tangan Tim Rekomendasi<span class="text-danger">*</span>
              </label>
            </div> -->
          </div>
          <!-- <div class="row border rounded px-3 py-2 mb-3 d-none">
            <div class="col-12 col-md-3">
              <label for="foto_bersama" class="form-label">
                Upload Foto bersama tim rekomendasi<span class="text-danger">*</span>
              </label>
            </div>
            <div class="">
              <img :src="currentRecord.foto_bersama" alt="" style="
                  width: 1in;
                  height: 1in;
                  object-fit: cover;
                  object-position: top;
                  cursor: pointer;
                " class="mb-2 border border-3" data-bs-toggle="modal" data-bs-target="#lihat_foto"
                @click="source_foto = currentRecord.foto_bersama" />
            </div>
            <div class="col-12 mb-1">
              <input type="file" name="foto_bersama" class="form-control" id="foto_bersama" ref="foto_bersama"
                @change="updateFile('foto_bersama')" accept="image/png, image/gif, image/jpeg" :required="currentRecord.foto_bersama ==
                  'https://s7-api.arthaskyline.com/default.png'
                  " />
            </div>
          </div> -->
        </div>

        <div class="border border-2 rounded-top border-bottom-0 px-2 py-2 fw-bold fst-italic">
          KELENGKAPAN DATA
        </div>
        <div class="w-100 h-100 border rounded-bottom border-2 px-4 py-3 mb-5">
          <div class="row border rounded px-3 py-2 mb-3">
            <div class="col-12">
              <label for="foto_kip" class="form-label">
                Surat kip/pkh/kks/srt keterangan penghasilan orangtua dari
                kelurahan / desa ( pilih salah satu )<span class="text-danger">*</span>
              </label>
            </div>
            <div class="">
              <img :src="currentRecord.foto_kip" alt="" style="
                  width: 1in;
                  height: 1in;
                  object-fit: cover;
                  object-position: top;
                  cursor: pointer;
                " class="mb-2 border border-3" data-bs-toggle="modal" data-bs-target="#lihat_foto"
                @click="source_foto = currentRecord.foto_kip" />
            </div>
            <div class="col-12 mb-1">
              <input type="file" name="foto_kip" class="form-control" id="foto_kip" ref="foto_kip"
                @change="updateFile('foto_kip')" accept="image/png, image/gif, image/jpeg" :required="currentRecord.foto_kip ==
                  'https://s7-api.arthaskyline.com/default.png'
                  " />
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-12 col-md-3">
              <label for="foto_penghasilan_orang_tua" class="form-label">
                Surat Keterangan Penghasilan Orang Tua dari kelurahan/desa<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <input type="file" name="foto_penghasilan_orang_tua" class="form-control" id="foto_penghasilan_orang_tua"
                ref="foto_penghasilan_orang_tua" required />
            </div>
          </div> -->
          <div class="row border rounded px-3 py-2 mb-3">
            <div class="col-12 col-md-3">
              <label for="foto_kartu_keluarga" class="form-label">
                Foto Kartu Keluarga<span class="text-danger">*</span>
              </label>
            </div>
            <div class="">
              <img :src="currentRecord.foto_kartu_keluarga" alt="" style="
                  width: 1in;
                  height: 1in;
                  object-fit: cover;
                  object-position: top;
                  cursor: pointer;
                " class="mb-2 border border-3" data-bs-toggle="modal" data-bs-target="#lihat_foto"
                @click="source_foto = currentRecord.foto_kartu_keluarga" />
            </div>
            <div class="col-12 mb-1">
              <input type="file" name="foto_kartu_keluarga" class="form-control" id="foto_kartukeluarga"
                ref="foto_kartu_keluarga" @change="updateFile('foto_kartu_keluarga')"
                accept="image/png, image/gif, image/jpeg" :required="currentRecord.foto_kartu_keluarga ==
                  'https://s7-api.arthaskyline.com/default.png'
                  " />
            </div>
          </div>
          <div class="row border rounded px-3 py-2 mb-3">
            <div class="col-12">
              <label for="foto_ktp_ayah" class="form-label">
                Foto Kartu KTP Ayah<span class="text-danger">*</span>
              </label>
            </div>
            <div class="">
              <img :src="currentRecord.foto_ktp_ayah" alt="" style="
                  width: 1in;
                  height: 1in;
                  object-fit: cover;
                  object-position: top;
                  cursor: pointer;
                " class="mb-2 border border-3" data-bs-toggle="modal" data-bs-target="#lihat_foto"
                @click="source_foto = currentRecord.foto_ktp_ayah" />
            </div>
            <div class="col-12 mb-1">
              <input type="file" name="foto_ktp_ayah" @change="updateFile('foto_ktp_ayah')" class="form-control"
                id="foto_ktp_ayah" ref="foto_ktp_ayah" accept="image/png, image/gif, image/jpeg" :required="currentRecord.foto_ktp_ayah ==
                  'https://s7-api.arthaskyline.com/default.png'
                  " />
            </div>
          </div>
          <div class="row border rounded px-3 py-2 mb-3">
            <div class="col-12 col-md-3">
              <label for="foto_ktp_ibu" class="form-label">
                Foto Kartu KTP Ibu<span class="text-danger">*</span>
              </label>
            </div>
            <div class="">
              <img :src="currentRecord.foto_ktp_ibu" alt="" style="
                  width: 1in;
                  height: 1in;
                  object-fit: cover;
                  object-position: top;
                  cursor: pointer;
                " class="mb-2 border border-3" data-bs-toggle="modal" data-bs-target="#lihat_foto"
                @click="source_foto = currentRecord.foto_ktp_ibu" />
            </div>
            <div class="col-12 mb-1">
              <input type="file" name="foto_ktp_ibu" @change="updateFile('foto_ktp_ibu')" class="form-control"
                id="foto_ktp_ibu" ref="foto_ktp_ibu" accept="image/png, image/gif, image/jpeg" :required="currentRecord.foto_ktp_ibu ==
                  'https://s7-api.arthaskyline.com/default.png'
                  " />
            </div>
          </div>
          <div class="row border rounded px-3 py-2 mb-3">
            <div class="col-12 col-md-3">
              <label for="foto_ktp_siswa" class="form-label">
                Foto KTP Mahasiswa/i
                <span class="text-danger">*</span>
              </label>
            </div>
            <div class="">
              <img :src="currentRecord.foto_ktp_siswa" alt="" style="
                  width: 1in;
                  height: 1in;
                  object-fit: cover;
                  object-position: top;
                  cursor: pointer;
                " class="mb-2 border border-3" data-bs-toggle="modal" data-bs-target="#lihat_foto"
                @click="source_foto = currentRecord.foto_ktp_siswa" />
            </div>
            <div class="col-12 mb-1">
              <input type="file" name="foto_ktp_siswa" @change="updateFile('foto_ktp_siswa')" class="form-control"
                id="foto_ktp_siswa" ref="foto_ktp_siswa" accept="image/png, image/gif, image/jpeg" :required="currentRecord.foto_ktp_siswa ==
                  'https://s7-api.arthaskyline.com/default.png'
                  " />
            </div>
          </div>
          <div class="row border rounded px-3 py-2 mb-3">
            <div class="col-12 col-md-3">
              <label for="foto_sktl" class="form-label">
                Foto SKTL<span class="text-danger">*</span>
              </label>
            </div>
            <div class="">
              <img :src="currentRecord.foto_sktl" alt="" style="
                  width: 1in;
                  height: 1in;
                  object-fit: cover;
                  object-position: top;
                  cursor: pointer;
                " class="mb-2 border border-3" data-bs-toggle="modal" data-bs-target="#lihat_foto"
                @click="source_foto = currentRecord.foto_sktl" />
            </div>
            <div class="col-12 mb-1">
              <input type="file" name="foto_sktl" class="form-control" @change="updateFile('foto_sktl')" id="foto_sktl"
                ref="foto_sktl" accept="image/png, image/gif, image/jpeg" :required="currentRecord.foto_sktl ==
                  'https://s7-api.arthaskyline.com/default.png'
                  " />
            </div>
          </div>
          <div class="row border rounded px-3 py-2 mb-3">
            <div class="col-12 col-md-3">
              <label for="foto_rekening_listrik" class="form-label">
                Foto Rekening Listrik (3 Bulan terakhir)<span class="text-danger"></span>
              </label>
            </div>
            <div class="">
              <img :src="currentRecord.foto_rekening_listrik" alt="" style="
                  width: 1in;
                  height: 1in;
                  object-fit: cover;
                  object-position: top;
                  cursor: pointer;
                " class="mb-2 border border-3" data-bs-toggle="modal" data-bs-target="#lihat_foto"
                @click="source_foto = currentRecord.foto_rekening_listrik" />
            </div>
            <div class="col-12 mb-1">
              <input type="file" name="foto_rekening_listrik" @change="updateFile('foto_rekening_listrik')"
                class="form-control" id="foto_rekening_listrik" ref="foto_rekening_listrik"
                accept="image/png, image/gif, image/jpeg" />
            </div>
          </div>

          <div class="row border rounded px-3 py-2 mb-3">
            <div class="col-12 col-md-3">
              <label for="foto_rekening_air" class="form-label">
                Foto Rekening Air<span class="text-danger"></span>
              </label>
            </div>
            <div class="">
              <img :src="currentRecord.foto_rekening_air" alt="" style="
                  width: 1in;
                  height: 1in;
                  object-fit: cover;
                  object-position: top;
                  cursor: pointer;
                " class="mb-2 border border-3" data-bs-toggle="modal" data-bs-target="#lihat_foto"
                @click="source_foto = currentRecord.foto_rekening_air" />
            </div>
            <div class="col-12 mb-3">
              <input type="file" name="foto_rekening_air" class="form-control" id="foto_rekening_air"
                @change="updateFile('foto_rekening_air')" ref="foto_rekening_air"
                accept="image/png, image/gif, image/jpeg" />
            </div>
          </div>
          <div class="row border rounded px-3 py-2 mb-3">
            <div class="col-12 col-md-3">
              <label for="foto_keluarga" class="form-label">
                Foto Keluarga<span class="text-danger">*</span>
              </label>
            </div>
            <div class="">
              <img :src="currentRecord.foto_keluarga" alt="" style="
                  width: 1in;
                  height: 1in;
                  object-fit: cover;
                  object-position: top;
                  cursor: pointer;
                " class="mb-2 border border-3" data-bs-toggle="modal" data-bs-target="#lihat_foto"
                @click="source_foto = currentRecord.foto_keluarga" />
            </div>
            <div class="col-12 mb-1">
              <input type="file" name="foto_keluarga" class="form-control" id="foto_keluarga" ref="foto_keluarga"
                @change="updateFile('foto_keluarga')" accept="image/png, image/gif, image/jpeg" :required="currentRecord.foto_keluarga ==
                  'https://s7-api.arthaskyline.com/default.png'
                  " />
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-12 col-md-3">
              <label for="no_hp" class="form-label">
                Foto Rumah(Tampak Depan)<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <input
                type="file"
                name="nama"
                class="form-control"
                id="nis"
                 
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-3">
              <label for="no_hp" class="form-label">
                Foto Rumah(Dalam Rumah)<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <input
                type="file"
                name="nama"
                class="form-control"
                id="nis"
                 
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-3">
              <label for="no_hp" class="form-label">
                Foto Rumah(Dapur Rumah)<span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <input
                type="file"
                name="nama"
                class="form-control"
                id="nis"
                 
              />
            </div>
          </div> -->
          <div class="row border borde-2 rounded px-3 py-2 mb-3">
            <div class="col-12 col-md-3">
              <label for="foto_pas" class="form-label">
                Pas Foto 3x4<span class="text-danger">*</span>
              </label>
            </div>
            <div class="">
              <img :src="currentRecord.foto_pas" alt="" style="
                  width: 1in;
                  height: 1in;
                  object-fit: cover;
                  object-position: top;
                  cursor: pointer;
                " class="mb-2 border border-3" data-bs-toggle="modal" data-bs-target="#lihat_foto"
                @click="source_foto = currentRecord.foto_pas" />
            </div>
            <div class="col-12 mb-1">
              <input type="file" name="foto_pas" class="form-control" id="foto_pas" ref="foto_pas"
                @change="updateFile('foto_pas')" accept="image/png, image/gif, image/jpeg" :required="currentRecord.foto_pas ==
                  'https://s7-api.arthaskyline.com/default.png'
                  " />
            </div>
          </div>
          <div class="row border rounded px-3 py-2 mb-3">
            <div class="col-12 col-md-3">
              <label for="foto_ptn" class="form-label">
                Foto Bukti Lulus PTN & Bukti UKT<span class="text-danger"></span>
              </label>
            </div>
            <div class="">
              <img :src="currentRecord.foto_ptn" alt="" style="
                  width: 1in;
                  height: 1in;
                  object-fit: cover;
                  object-position: top;
                  cursor: pointer;
                " class="mb-2 border border-3" data-bs-toggle="modal" data-bs-target="#lihat_foto"
                @click="source_foto = currentRecord.foto_ptn" />
            </div>
            <div class="col-12 mb-1">
              <input type="file" name="foto_ptn" @change="updateFile('foto_ptn')" class="form-control" id="foto_ptn"
                ref="foto_ptn" accept="image/png, image/gif, image/jpeg" />
            </div>
          </div>
          <div class="row border rounded px-3 py-2 mb-3">
            <div class="col-12 col-md-3">
              <label for="foto_rumah_depan" class="form-label">
                Foto Rumah(Tampak Depan)<span class="text-danger">*</span>
              </label>
            </div>
            <div class="">
              <img :src="currentRecord.foto_rumah_depan" alt="" style="
                  width: 1in;
                  height: 1in;
                  object-fit: cover;
                  object-position: top;
                  cursor: pointer;
                " class="mb-2 border border-3" data-bs-toggle="modal" data-bs-target="#lihat_foto"
                @click="source_foto = currentRecord.foto_rumah_depan" />
            </div>
            <div class="col-12 mb-1">
              <input type="file" name="foto_rumah_depan" class="form-control" @change="updateFile('foto_rumah_depan')"
                id="Foto_rumah_depan" ref="foto_rumah_depan" accept="image/png, image/gif, image/jpeg" :required="currentRecord.foto_rumah_depan ==
                  'https://s7-api.arthaskyline.com/default.png'
                  " />
            </div>
          </div>
          <div class="row border rounded px-3 py-2 mb-3">
            <div class="col-12 col-md-3">
              <label for="foto_rumah_dalam" class="form-label">
                Foto Rumah(Tampak Tengah)<span class="text-danger">*</span>
              </label>
            </div>
            <div class="">
              <img :src="currentRecord.foto_rumah_dalam" alt="" style="
                  width: 1in;
                  height: 1in;
                  object-fit: cover;
                  object-position: top;
                  cursor: pointer;
                " class="mb-2 border border-3" data-bs-toggle="modal" data-bs-target="#lihat_foto"
                @click="source_foto = currentRecord.foto_rumah_dalam" />
            </div>
            <div class="col-12 mb-1">
              <input type="file" name="foto_rumah_dalam" class="form-control" @change="updateFile('foto_rumah_dalam')"
                id="foto_rumah_dalam" ref="foto_rumah_dalam" accept="image/png, image/gif, image/jpeg" :required="currentRecord.foto_rumah_dalam ==
                  'https://s7-api.arthaskyline.com/default.png'
                  " />
            </div>
          </div>
          <div class="row border rounded px-3 py-2 mb-3">
            <div class="col-12 col-md-3">
              <label for="foto_rumah_dapur" class="form-label">
                Foto Rumah(Tampak Belakang)<span class="text-danger">*</span>
              </label>
            </div>
            <div class="">
              <img :src="currentRecord.foto_rumah_dapur" alt="" style="
                  width: 1in;
                  height: 1in;
                  object-fit: cover;
                  object-position: top;
                  cursor: pointer;
                " class="mb-2 border border-3" data-bs-toggle="modal" data-bs-target="#lihat_foto"
                @click="source_foto = currentRecord.foto_rumah_dapur" />
            </div>
            <div class="col-12 mb-1">
              <input type="file" name="foto_rumah_dapur" class="form-control" @change="updateFile('foto_rumah_dapur')"
                id="foto_rumah_dapur" ref="foto_rumah_dapur" accept="image/png, image/gif, image/jpeg" :required="currentRecord.foto_rumah_dapur ==
                  'https://s7-api.arthaskyline.com/default.png'
                  " />
            </div>
          </div>

          <!-- <div class="row">
            <div class="col-12 col-md-3">
              <label for="foto_bersama" class="form-label">
                Upload Foto bersama <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-12 col-md-4 mb-3">
              <input
                type="file"
                name="foto_bersama"
                class="form-control"
                id="nis"
                 
              />
            </div>
          </div> -->
          <div class="row">
            <div class="col-12 mb-2">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" required />
                <label class="form-check-label" for="flexCheckDefault">
                  Dengan ini saya menyatakan dengan penuh kesadaran bahwa data
                  yang saya isi dalam formulir ini adalah benar. Saya bersedia
                  diberikan sanksi apabila melanggar ketentuan dan memberikan
                  informasi yang tidak benar.
                </label>
              </div>
            </div>
            <div class="col-lg-3 col-md-4 col-12 px-2 mb-5 position-relative" style="height: 100px"
              v-show="currentRecord.tanda_tangan == ''">
              <vue-perfect-signature v-model="signatureData" :stroke-options="{
                size: 5,
                height: 1,
                width: 1,
                background: '#fff',
                penColor: '#000',
              }" ref="signaturePad" class="border rounded" />
              <div class="w-100 text-center">Tanda Tangan Calon Mahasiswa</div>
              <button type="button" class="position-absolute btn btn-danger btn-sm top-0"
                @click="clearSignature(signaturePad)">
                x
              </button>
            </div>

            <div class="col-9"></div>
            <div class="col-12 bg-warning border rounded px-2 py-2 mb-2">
              Apabila kami temukan penyalahgunaan formulir atau pengutipan dana
              dalam segala proses Program KIP Kuliah akan kami tindak sesuai
              dengan ketentuan hukum yang berlaku
            </div>
            <div class="col-12 bg-warning px-2 py-2 rounded">
              PERHATIAN : Segala Pengurusan, pengusulan, dan pencairan dana
              program KIP Kuliah Aspirasi Dr. SOFYAN TAN ini tidak dipungut
              biaya ( apabila ada yang menyalahgunakan formulir dan atau
              mengutip dana terkait Pengusulan maupun pencairan dana KIP KULIAH
              agar segera melapor ke
              <b>Rumah Aspirasi Dr. Sofyan Tan, Komplek CBD Polonia Blok G No.87
                Polonia, Medan</b>
            </div>

            <div class="row">
              <div class="col-12 mt-3">
                <div class="d-flex justify-content-between align-items-center">
                  <div></div>
                  <div class="fs-7 opacity-50">Powered By Artha Skyline</div>
                  <div>
                    <button type="submit" class="btn btn-danger" :disabled="isGambarSukses == false">
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <button class="btn" ref="modalShowButton" data-bs-toggle="modal" data-bs-target="#tambah_data" hidden></button>
    <div class="modal fade" id="tambah_data" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="label_data_karyawan">Kode Akses</h5>
            <!-- <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button> -->
          </div>
          <form @submit.prevent="verifikasi()">
            <div class="modal-body">
              <div class="mb-3">
                <label for="kode" class="form-label">
                  Masukan kode yang dikirimkan via whatsapp atau email
                </label>
                <input type="text" class="form-control" v-model="currentRecord.id" placeholder="Kode" />
              </div>
            </div>
            <div class="modal-footer">
              <!-- <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Batal
            </button> -->
              <button type="button" ref="close_modal" data-bs-dismiss="modal" aria-label="Close" hidden></button>
              <button type="submit" class="btn btn-primary">Verifikasi</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal fade" id="lihat_foto" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="mb-3 w-100 text-center">
          <img :src="source_foto" alt="" style="max-width: 100vw; max-height: 100vh" />
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="text-end">
    <span class="me-2">Selanjutnya</span>
    <button type="submit" class="btn btn-success rounded-circle py-2">
      <i class="bx bx-arrow-back bx-rotate-180"></i>
    </button>
  </div> -->
</template>

<script>
// @ is an alias to /src
import Cookies from "js-cookie";
import {
  goPage,
  base_url,
  AlertPopup,
  ShowLoading,
  CloseLoading,
  AlertPopupGoToURL,
  ErrorConnectionTimeOut,
  JustYearNow,
  base_url_wilayah_indonesia,
  randomString,
  AlertBottomError,
} from "@/assets/javascript/function";
import iziToast from "izitoast";
import imageCompression from "browser-image-compression";
import NavbarDefault from "@/components/NavbarDefault.vue";
import VuePerfectSignature from "v-perfect-signature";
import axios from "axios";
import VueScrollTo from "vue-scrollto";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      source_foto: "",
      scroll_field: "",
      isGambarSukses: true,
      isMobile: false,
      position: "",
      kunci_tahun_lulus_belakang: "",
      kunci_tahun_lulus_depan: "",
      page: "ra_calon",
      api: "",
      ID: "",
      name: "testing",
      selectedOption: "Islam",
      selectedOption2: "Pria",
      selectedOptionKabupaten: "",
      selectedOptionKecamatan: "",
      statusOrangTua: "Kandung",
      kecamatanList: [],
      kabupatenList: [],
      kelurahanList: [],
      tahunLulusList: [],

      sumberListrikList: [
        {
          nama: "Sistem Pembayaran Pascabayar",
        },
        {
          nama: "Sistem Pembayaran Prabayar",
        },
      ],
      kepemilikanList: [
        {
          nama: "Milik Sendiri",
        },
        {
          nama: "Kontrak/Sewa",
        },
        {
          nama: "Bebas Sewa(menumpang)",
        },
        {
          nama: "Dinas",
        },
      ],
      statusOrangTuaList: [
        {
          nama: "Kandung",
        },
        {
          nama: "Tiri",
        },
        {
          nama: "Angkat",
        },
        {
          nama: "Lainnya",
        },
      ],
      pendidikanTerakhirList: [
        {
          nama: "Tidak/ Belum Sekolah",
        },
        {
          nama: "SD/Sederajat",
        },
        {
          nama: "SLTP/Sederajat",
        },
        {
          nama: "SLTA/Sederajat",
        },
        {
          nama: "Diploma ",
        },
        {
          nama: "S1",
        },
        {
          nama: "S2",
        },
        {
          nama: "S3",
        },
      ],
      provinsiList: [],
      kotaList: [],
      pekerjaanList: [
        {
          nama: "PNS",
        },
        {
          nama: "PEG SWASTA",
        },
        {
          nama: "WIRAUSAHA",
        },
        {
          nama: "TNI/POLRI",
        },
        {
          nama: "PETANI",
        },
        {
          nama: "NELAYAN",
        },
        {
          nama: "LAINNYA",
        },
      ],
      kampusList: [],
      prodi: "", // a string to store the selected option
      currentRecord: {
        nama_lengkap: "",
        jenis_kelamin: "",
        agama: "",
        no_ktp: "",
        tempat_lahir: "",
        tanggal_lahir: "",
        alamat_siswa: "",
        provinsi: "",
        kecamatan: "",
        kabupaten: "",
        kelurahan: "",
        kota: "",
        lingkungan: "",
        kodepos: "",
        telepon_siswa: "",
        whatsapp: "",

        //data keluarga
        nama_ayah: "",
        pekerjaan_ayah: "",
        pendidikan_terakhir_ayah: "",
        nama_ibu: "",
        pekerjaan_ibu: "",
        pendidikan_terakhir_ibu: "",
        jumlah_tanggungan: "",
        telepon_ortu: "",
        whatsapp_ortu: "",
        status_ortu: "",
        penghasilan_ayah: "",
        penghasilan_ibu: "",

        //pendidikan
        nama_sekolah: "",
        npsn_sekolah: "",
        nisn_sekolah: "",
        jurusan_sekolah: "",
        tahun_lulus_sekolah: "",
        prestasi1_sekolah: "",
        prestasi2_sekolah: "",

        //pilihan
        nama_pt: "",
        alamat_pt: "",
        fakultas_pt: "",
        prodi_pt: "",
        nim_pt: "",

        //rumah tinggal keluarga
        alamat_rumah: "",
        kepemilikan_rumah: "Bebas Sewa(menumpang)",
        tahun_perolehan_rumah: "",
        sumber_listrik_rumah: "",
        luas_bangunan_rumah: "",

        //aku media sosial
        facebook: "",
        instagram: "",
        twitter: "",
        tiktok: "",
        youtube: "",

        //tim rekomendasi
        nama_rekomendasi: "",
        pekerjaan_rekomendasi: "",
        jabatan_rekomendasi: "",
        hp_rekomendasi: "",
        alamat_rekomendasi: "",
        foto_bersama: "",
        tanda_tangan: "",

        foto_kip: "",
        foto_kartu_keluarga: "",
        foto_ktp_ayah: "",
        foto_ktp_ibu: "",
        foto_ktp_siswa: "",
        foto_sktl: "",
        foto_rekening_listrik: "",
        foto_rekening_air: "",
        foto_keluarga: "",
        foto_pas: "",
        foto_ptn: "",

        //bekas
        nama_ijazah: "",
        warga_negara: "",
        nama_ibu_kandung: "",
        nik_ayah: "",
        nik_ibu: "",

        //tahun lulus
        tahunLulusList: [],

        id: "",
      },
      signatureData: null,
      // signatureData2: null,

      tahunAktif: "",
    };
  },
  name: "HomeView",
  components: { NavbarDefault, VuePerfectSignature },
  methods: {
    setAlamatPt() {
      const index = this.kampusList.findIndex(
        (record) => record.nama == this.currentRecord.nama_pt
      );
      this.currentRecord.alamat_pt = this.kampusList[index].alamat;
    },
    async compressImage(file) {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(file, options);
        return this.blobToFile(compressedFile, compressedFile.name);
        // const imageUrl = URL.createObjectURL(compressedFile);
        // console.log(imageUrl);
        // this.imageUrl = imageUrl;
      } catch (error) {
        console.log("compression error:", error);
      }
    },
    ShowModal() {
      this.$refs.modalShowButton.click();
    },
    provinsi_index() {
      ShowLoading();
      axios
        .post(base_url_wilayah_indonesia + "provinsi_index", "")
        .then((response) => {
          if (response.data.status == true) {
            // AlertPopup("success", response.data.message, "", 1500, false);
            this.provinsiList = response.data.data;
          }
          if (response.data.status == false) {
            AlertBottomError("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    kabupaten_index(id, jenis) {
      ShowLoading();
      const formData = new FormData();
      formData.append("id", id);

      axios
        .post(base_url_wilayah_indonesia + "kabupaten_index", formData)
        .then((response) => {
          if (response.data.status == true) {
            if (jenis == "ortu") {
              this.kotaList_ortu = response.data.data;
              console.log(this.kotaList_ortu);
            }
            if (jenis == "siswa") {
              this.kotaList = response.data.data;
            }
            if (jenis == "sekolah") {
              this.kotaList_sekolah = response.data.data;
            }
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertBottomError("error", "", response.data.message, 1500, false);
            CloseLoading();
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    kecamatan_index(id, jenis) {
      ShowLoading();
      const formData = new FormData();
      formData.append("id", id);
      axios
        .post(base_url_wilayah_indonesia + "kecamatan_index", formData)
        .then((response) => {
          if (response.data.status == true) {
            if (jenis == "ortu") {
              this.kecamatanList_ortu = response.data.data;
            }
            if (jenis == "siswa") {
              this.kecamatanList = response.data.data;
            }
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertBottomError("error", "", response.data.message, 1500, false);
            CloseLoading();
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    blobToFile(blob, fileName) {
      const options = {
        type: blob.type,
        lastModified: blob.lastModified,
      };

      const file = new File([blob], fileName, options);
      return file;
    },
    kelurahan_index(id, jenis) {
      ShowLoading();
      const formData = new FormData();
      formData.append("id", id);
      axios
        .post(base_url_wilayah_indonesia + "kelurahan_index", formData)
        .then((response) => {
          if (response.data.status == true) {
            if (jenis == "ortu") {
              this.kelurahanList_ortu = response.data.data;
            }
            if (jenis == "siswa") {
              this.kelurahanList = response.data.data;
            }
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertBottomError("error", "", response.data.message, 1500, false);
            CloseLoading();
          }
          CloseLoading();
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    checkInputValidity_wilayah(pilihan) {
      const enteredValue = this.currentRecord["" + pilihan];
      let validOptions;

      if (pilihan == "agama") {
        validOptions = this.agamaList.map((option) => option.nama);
      }
      if (pilihan == "provinsi") {
        validOptions = this.provinsiList.map((option) => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord["" + pilihan] = "";
        } else {
          let filter = this.provinsiList.filter((provinsi) =>
            provinsi.nama.includes(this.currentRecord.provinsi)
          );
          this.kabupaten_index(filter[0].id, "siswa");
        }
      }
      if (pilihan == "kota") {
        validOptions = this.kotaList.map((option) => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord["" + pilihan] = "";
        } else {
          let filter = this.kotaList.filter((kota) =>
            kota.nama.includes(this.currentRecord.kota)
          );
          this.kecamatan_index(filter[0].id, "siswa");
        }
      }
      if (pilihan == "kecamatan") {
        validOptions = this.kecamatanList.map((option) => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord["" + pilihan] = "";
        } else {
          let filter = this.kecamatanList.filter((provinsi) =>
            provinsi.nama.includes(this.currentRecord.kecamatan)
          );
          this.kelurahan_index(filter[0].id, "siswa");
        }
      }
      if (pilihan == "kelurahan") {
        validOptions = this.kelurahanList.map((option) => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord["" + pilihan] = "";
        }
      }

      if (pilihan == "provinsi_ortu") {
        validOptions = this.provinsiList_ortu.map((option) => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord["" + pilihan] = "";
        } else {
          let filter = this.provinsiList_ortu.filter((provinsi) =>
            provinsi.nama.includes(this.currentRecord.provinsi_ortu)
          );
          this.kabupaten_index(filter[0].id, "ortu");
        }
      }
      if (pilihan == "kota_ortu") {
        validOptions = this.kotaList_ortu.map((option) => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord["" + pilihan] = "";
        } else {
          let filter = this.kotaList_ortu.filter((kota) =>
            kota.nama.includes(this.currentRecord.kota_ortu)
          );
          this.kecamatan_index(filter[0].id, "ortu");
        }
      }
      if (pilihan == "kecamatan_ortu") {
        validOptions = this.kecamatanList_ortu.map((option) => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord["" + pilihan] = "";
        } else {
          let filter = this.kecamatanList_ortu.filter((provinsi) =>
            provinsi.nama.includes(this.currentRecord.kecamatan_ortu)
          );
          this.kelurahan_index(filter[0].id, "ortu");
        }
      }
      if (pilihan == "kelurahan_ortu") {
        validOptions = this.kelurahanList_ortu.map((option) => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord["" + pilihan] = "";
        }
      }

      if (pilihan == "provinsi_sekolah") {
        validOptions = this.provinsiList_sekolah.map((option) => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord["" + pilihan] = "";
        } else {
          let filter = this.provinsiList_sekolah.filter((provinsi) =>
            provinsi.nama.includes(this.currentRecord.provinsi_sekolah)
          );
          this.kabupaten_index(filter[0].id, "sekolah");
        }
      }
      if (pilihan == "kota_sekolah") {
        validOptions = this.kotaList_sekolah.map((option) => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord["" + pilihan] = "";
        }
      }

      if (
        pilihan == "pendidikan_terakhir_ayah" ||
        pilihan == "pendidikan_terakhir_ibu"
      ) {
        validOptions = this.pendidikanTerakhirList.map((option) => option.nama);
      }

      if (
        pilihan == "ukuran_jas_akademis" ||
        pilihan == "ukuran_jas_akademis"
      ) {
        validOptions = this.ukuranJasList.map((option) => option.nama);
      }
    },
    HideModal() {
      this.$refs.close_modal.click();
    },
    saveData() {
      this.currentRecord.agama = this.selectedOption;
      this.currentRecord.jenis_kelamin = this.selectedOption2;
      this.currentRecord.prodi = this.prodi;
      localStorage.setItem("data_diri", JSON.stringify(this.currentRecord));
      goPage("data_akun");
    },
    indexData() {
      //note nanti diganti
      this.currentRecord.nama_ijazah = this.currentRecord.nama_lengkap;
      this.currentRecord.warga_negara = "WNI";
      if (
        Cookies.get("nama_jurusan") != "" ||
        Cookies.get("nama_jurusan") != null
      ) {
        this.prodi = Cookies.get("nama_jurusan");
      }

      if (localStorage.getItem("data_diri") !== null) {
        let data = JSON.parse(localStorage.getItem("data_diri"));
        this.currentRecord = Object.assign({}, data);

        if (this.currentRecord.agama != "") {
          this.selectedOption = "Islam";
        }
        if (this.currentRecord.jenis_kelamin != "") {
          this.selectedOption2 = "Pria";
        }

        this.currentRecord.prodi = this.prodi;
        CloseLoading();
      }
    },
    insert() {
      const index = this.kampusList.findIndex(
        (kampus) => kampus.nama === this.currentRecord.nama_pt
      );

      if (this.kampusList[index].tipe == "PTN") {
        Swal.fire({
          icon: "info",
          text: "Pastikan Anda telah terdaftar atau lulus pada universitas negri pilihan tersebut",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Ya, Sudah terdaftar",
          denyButtonText: `Tidak, Belum terdaftar`,
          denyButtonColor: "#efefef",
          confirmButtonColor: "#dc3545",
          customClass: {
            denyButton: "order-1",
            confirmButton: "order-2",
          },
          didOpen: () => {
            const denyButton = Swal.getDenyButton();
            denyButton.style.color = "black"; // Set your desired text color here
          },
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.insert_data();
          }
        });
      } else {
        this.insert_data();
      }
    },

    insert_data() {
      ShowLoading();
      const formData = new FormData();
      formData.append("url", this.page + "_update");

      //select option
      this.currentRecord.jenis_kelamin = this.selectedOption2;

      if (this.currentRecord.agama == "") {
        this.currentRecord.agama = this.selectedOption;
      }
      this.currentRecord.status_ortu = this.statusOrangTua;
      this.currentRecord.kabupaten = this.currentRecord.kota;

      // let no_hp = this.currentRecord.whatsapp;
      //no hp
      // this.currentRecord.whatsapp = "62" + no_hp.slice(1);

      Object.entries(this.currentRecord).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (this.currentRecord.tanda_tangan == "") {
        formData.append("tanda_tangan", this.saveSignature());
      } else {
        formData.append("tanda_tangan", "");
      }

      formData.append("id", this.currentRecord.kode_unik);
      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + this.page + "_update";
      } else {
        this.api = base_url + this.page + "_update";
      }

      if (this.saveSignature() === undefined) {
        if (this.currentRecord.tanda_tangan == "") {
          AlertPopup(
            "error",
            "tanda tangan masih kosong",
            "silahkan isi tanda tangan",
            1500,
            false
          );
        } else {
          axios
            .post(this.api, formData)
            .then((response) => {
              if (response.data.status == true) {
                // AlertPopup("success", response.data.message, "", 1500, false);
                // console.log(response.data);
                AlertPopupGoToURL(
                  "",
                  response.data.message,
                  true,
                  "report_register"
                );
                // goPage("report_register");
              }
              if (response.data.status == false) {
                AlertPopup("error", "", response.data.message, 1500, false);
              }
            })
            .catch((error) => {
              ErrorConnectionTimeOut(error);
            });
        }
      } else {
        axios
          .post(this.api, formData, { timeout: 30000 })
          .then((response) => {
            if (response.data.status == true) {
              // AlertPopup("success", response.data.message, "", 1500, false);
              // console.log(response.data);
              AlertPopupGoToURL(
                "",
                response.data.message,
                true,
                "report_register"
              );
            }
            if (response.data.status == false) {
              AlertPopup("error", "", response.data.message, 1500, false);
            }
          })
          .catch((error) => {
            AlertPopup(
              "error",
              "",
              "terjadi kesalahan silahkan coba lagi",
              1500,
              false
            );
            ErrorConnectionTimeOut(error);
          });
      }
    },
    verifikasi(id) {
      ShowLoading();
      const formData = new FormData();

      formData.append("url", this.page + "_details");
      formData.append("id", id);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + this.page + "_details";
      } else {
        this.api = base_url + this.page + "_details";
      }

      // Add a new record to the API
      axios
        .post(this.api, formData)
        .then((response) => {
          if (response.data.status == true) {
            this.tahunAktif = response.data.tahun_ajaran_aktif;
            // console.log(response.data);
            // delete file yang direturn dari api
            // delete response.data.data.tanda_tangan;
            // delete response.data.data.foto_rumah_depan;
            // delete response.data.data.foto_rumah_dalam;
            // delete response.data.data.foto_rumah_dapur;
            // delete response.data.data.foto_bersama;
            // delete response.data.data.tanda_tangan_rekomendasi;
            // delete response.data.data.tanda_tangan_tim_rekomendasi;

            //set select option
            // this.selectedOption2 = response.data.data.jenis_kelamin;
            // this.selectedOption = response.data.data.agama;
            // this.pekerjaan_ayah = response.data.data.pekerjaan_ayah;
            // this.pekerjaan_ibu = response.data.data.pekerjaan_ibu;
            // this.statusOrangTua = response.data.data.status_ortu;

            this.currentRecord = response.data.data;
            this.currentRecord.kota = this.currentRecord.kabupaten;
            // this.currentRecord.kabupaten =
            // this.kecamatanList = response.data.kecamatan;
            // this.kelurahanList = response.data.kelurahan;
            // this.kabupatenList = response.data.kabupaten;
            this.kampusList = response.data.kampus;
            if (response.data.data.nama_pt != "") {
              const index = this.kampusList.findIndex(
                (kampus) => kampus.nama === response.data.data.nama_pt
              );
              if (index !== -1) {
                this.kampusList = this.kampusList.filter((_, i) => i === index); // Filter out all elements except for the desired index
              }
            }

            // this.currentRecord.kota = response.data.data.kabupaten;

            this.selectedOption = this.currentRecord.agama;
            // if (this.currentRecord.agama != '') {
            //   this.selectedOption = 'Islam';
            // }
            this.selectedOption2 = this.currentRecord.jenis_kelamin;

            // if (this.currentRecord.jenis_kelamin == 'Pria') {
            //   this.selectedOption2 = 'Pria';
            // }

            // if (this.currentRecord.foto_bersama == "") {
            //   this.currentRecord.foto_bersama =
            //     "https://s7-api.arthaskyline.com/default.png";
            // }
            if (this.currentRecord.foto_kartu_keluarga == "") {
              this.currentRecord.foto_kartu_keluarga =
                "https://s7-api.arthaskyline.com/default.png";
            }

            if (this.currentRecord.foto_keluarga == "") {
              this.currentRecord.foto_keluarga =
                "https://s7-api.arthaskyline.com/default.png";
            }
            if (this.currentRecord.foto_kip == "") {
              this.currentRecord.foto_kip =
                "https://s7-api.arthaskyline.com/default.png";
            }
            if (this.currentRecord.foto_ktp_ayah == "") {
              this.currentRecord.foto_ktp_ayah =
                "https://s7-api.arthaskyline.com/default.png";
            }
            if (this.currentRecord.foto_ktp_ibu == "") {
              this.currentRecord.foto_ktp_ibu =
                "https://s7-api.arthaskyline.com/default.png";
            }

            if (this.currentRecord.foto_ktp_siswa == "") {
              this.currentRecord.foto_ktp_siswa =
                "https://s7-api.arthaskyline.com/default.png";
            }
            if (this.currentRecord.foto_pas == "") {
              this.currentRecord.foto_pas =
                "https://s7-api.arthaskyline.com/default.png";
            }
            if (this.currentRecord.foto_ptn == "") {
              this.currentRecord.foto_ptn =
                "https://s7-api.arthaskyline.com/default.png";
            }
            if (this.currentRecord.foto_rekening_air == "") {
              this.currentRecord.foto_rekening_air =
                "https://s7-api.arthaskyline.com/default.png";
            }
            if (this.currentRecord.foto_rekening_listrik == "") {
              this.currentRecord.foto_rekening_listrik =
                "https://s7-api.arthaskyline.com/default.png";
            }
            if (this.currentRecord.foto_sktl == "") {
              this.currentRecord.foto_sktl =
                "https://s7-api.arthaskyline.com/default.png";
            }
            if (this.currentRecord.foto_rumah_depan == "") {
              this.currentRecord.foto_rumah_depan =
                "https://s7-api.arthaskyline.com/default.png";
            }
            if (this.currentRecord.foto_rumah_dapur == "") {
              this.currentRecord.foto_rumah_dapur =
                "https://s7-api.arthaskyline.com/default.png";
            }
            if (this.currentRecord.foto_rumah_dalam == "") {
              this.currentRecord.foto_rumah_dalam =
                "https://s7-api.arthaskyline.com/default.png";
            }

            // this.currentRecord.whatsapp = "0" + data[0].whatsapp.slice(2);
            // this.currentRecord.whatsapp = "0" + response.data.data.whatsapp.slice(2);
            this.HideModal();
            // console.log(response.data.data);
            CloseLoading();

            // AlertPopup("success", "Silahkan Isi Data", "", 1500, false);
            if (this.scroll_field != undefined) {
              this.scrollToElement(this.scroll_field);
            }
          } else {
            AlertPopup("error", "", response.data.message, 1500, false);
            goPage("/");
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    updateSelectedOption(option) {
      this.selectedOption = option;
    },
    updateSelectedOption2(option) {
      this.selectedOption2 = option;
    },
    updateSelectedOptionKecamatan(option) {
      this.selectedOptionKecamatan = option;
    },
    updateSelectedOptionKabupaten(option) {
      this.selectedOptionKabupaten = option;
    },

    saveSignature() {
      const dataURL = this.$refs.signaturePad.toDataURL();
      return dataURL;
    },
    clearSignature() {
      this.$refs.signaturePad.clear();
    },
    checkInputValidity(pilihan) {
      const enteredValue = this.currentRecord["" + pilihan];
      let validOptions;
      if (
        pilihan == "pendidikan_terakhir_ayah" ||
        pilihan == "pendidikan_terakhir_ibu"
      ) {
        validOptions = this.pendidikanTerakhirList.map((option) => option.nama);
        if (!validOptions.includes(enteredValue)) {
          this.currentRecord["" + pilihan] = "";
        }
      }
    },

    delay() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 5000); // Sleep for 1 second
      });
    },

    async updateFile(nama_file) {
      this.isGambarSukses = false;
      if (this.$refs[nama_file].files[0] != undefined) {
        // ShowLoading();
        iziToast.info({
          timeout: 0,
          title: "Sedang Upload Dokumen",
          position: this.position,
        });

        var toast = document.querySelector(".iziToast");
        this.currentRecord[nama_file] = "";

        this.currentRecord[nama_file] =
          "https://s7-api.arthaskyline.com/loading.gif";
        const formData = new FormData();

        // Object.entries(this.newRecord).forEach(([key, value]) => {
        //   formData.append(key, value);
        // });
        var filecompress = await this.compressImage(
          this.$refs[nama_file].files[0]
        );
        // console.log(Math.round(filecompress.size / 1024));
        formData.append("id", this.currentRecord.kode_unik);
        formData.append("url", "ra_calon_update_file");
        formData.append(nama_file, filecompress);

        if (process.env.NODE_ENV === "development") {
          this.api = base_url + "ra_calon_update_file";
        } else {
          this.api = base_url + "ra_calon_update_file";
        }
        // Add a new record to the API
        axios
          .post(this.api, formData)
          .then((response) => {
            if (response.data.status == true) {
              // this.currentRecord[nama_file] = "https://s7-api.arthaskyline.com/confetti.gif";
              iziToast.hide({}, toast);
              let nama_file2 = nama_file.replace(/_/g, " ");
              iziToast.success({
                timeout: 3000,
                title: nama_file2 + " berhasil di update",
                position: this.position,
              });

              // CloseLoading();
              this.isGambarSukses = true;

              //add delay on HERE CHAT GPT
              // setTimeout(() => {
              //   this.currentRecord[nama_file] = response.data.file_url;
              // }, 3000);

              this.currentRecord[nama_file] =
                response.data.file_url + "?v=" + randomString();
            }
            if (response.data.status == false) {
              // AlertPopup("error", "", response.data.message, 1500, false);
              this.isGambarSukses = false;
              iziToast.hide({}, toast);
              iziToast.error({
                timeout: 3000,
                title: response.data.message,
                position: this.position,
              });
            }
          })
          .catch((error) => {
            AlertPopup(
              "error",
              "",
              "terjadi kesalahan silahkan coba lagi",
              1500,
              false
            );
            ErrorConnectionTimeOut(error);
            console.log(error);
          });
      }
    },
    scrollToElement(nama) {
      const element = this.$refs[nama];
      const paddingTop = 270; // Specify the top padding value in pixels

      if (element) {
        this.$scrollTo.scrollTo(element, 500, { offset: -paddingTop });
      } else {
        console.error(`Element with ref ${nama} not found.`);
      }
    },
  },

  mounted() {
    // this.indexData();
    this.$scrollTo = VueScrollTo;
    const str = this.$route.fullPath;
    let slug = str.split("=").pop();
    this.scroll_field = slug.split("#")[1];
    slug = slug.split("#")[0];

    this.verifikasi(slug);

    // this.ShowModal();

    this.kunci_tahun_lulus_belakang = JustYearNow() - 2;
    this.kunci_tahun_lulus_depan = JustYearNow();

    // Generate the year list
    for (
      let list = this.kunci_tahun_lulus_belakang;
      list <= this.kunci_tahun_lulus_depan;
      list++
    ) {
      this.tahunLulusList.push({ nama: list });
    }
    this.provinsi_index();
    const userAgent = navigator.userAgent.toLowerCase();
    this.isMobile =
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );

    if (this.isMobile == false) {
      this.position = "bottomRight";
    } else {
      this.position = "topLeft";
    }
  },
};
</script>

<style></style>
